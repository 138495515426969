import React, { useState } from "react";
import { collection, query, getDocs, orderBy, doc, setDoc, deleteDoc } from 'firebase/firestore';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StandardToastContainer } from '../components/StandardToastContainer';
import { toast } from 'react-toastify';

import { db } from '../lib/firebase_config.js';
import { runMode } from '../lib/getRunMode';
import { validateSection, emptyErrorObject } from '../lib/validateSection';

import '../styles/globalstyles.css';

const emptySection = {
    sectionHeader: "",
    sectionId: "",
    sectionPrefix: "",
    sectionSequenceNumber: 0,
    sectionType: "date_title",
    errorObject: { ...emptyErrorObject }
}

function SectionsTable(props) {

    const [sectionsTableState, setSectionsTableState] = useState({
        sections: props.sections,
    });

    async function refreshSectionsTable() { // triggers a table refresh following inserts and deletes initiated by SectionsTable's subordinate components

        const sections = [];

        const sectionsCollRef = collection(db, runMode + "sections");
        const sectionsQuery = query(sectionsCollRef, orderBy("sectionSequenceNumber", "asc"));
        const sectionsSnapshot = await getDocs(sectionsQuery);
        for (let i = 0; i < sectionsSnapshot.size; i++) {
            console.log(sectionsSnapshot.docs[i].data().sectionSequenceNumber)
            sections.push({ ...sectionsSnapshot.docs[i].data(), docId: sectionsSnapshot.docs[i].id });
        }

        setSectionsTableState({ ...sectionsTableState, sections: sections })
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1); //removes the startIndex item from result and puts it in removed vafiable
        result.splice(endIndex, 0, removed); // puts the removed item back at endIndex

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging : document.body.style.backgroundColor is the current background colour
        background: isDragging ? "lightgreen" : document.body.style.backgroundColor,

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : document.body.style.backgroundColor,
    });

    async function onDragEnd(result) {

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        // result.source.index tells you where the dragged item came from and
        // result.destination.index tells you where it has been dropped
        const reorderedSections = reorder(
            sectionsTableState.sections,
            result.source.index,
            result.destination.index
        );

        // reorderedSections now contains a re-ordered copy of the original sections. THe code now needs to go through
        // this, changing each row's sectionSequenceNumber to correspond to its new row position and then update the
        // "sections" collection

        for (let i= 0; i<reorderedSections.length; i++) {

            const sectionsDocRef = doc(db, runMode + "sections", reorderedSections[i].docId);
            await setDoc(sectionsDocRef, {"sectionSequenceNumber": i}, { merge: true });

        };

        // and now call refreshSectionsTable() to get the state updated with the new ordering

        refreshSectionsTable();

        toast.success("Yay, sections re-ordered")

    }

    return (
        <div>
            <StandardToastContainer />
            <h2 style={{
                textAlign: "center",
                marginTop: "3vh"
            }}>Configure Sections</h2><br />

            <div style={{ display: "flex", justifyContent: "center", padding: "1rem 0 1rem 0" }}>
                <SectionsTableInsertLine section={emptySection} refreshSectionsTable={refreshSectionsTable} refreshManager={props.refreshManager} />
            </div>

            <hr style={{ width: '25%', height: '2px', background: 'black' }}></hr>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >

                            {sectionsTableState.sections.map((section, index) => {
                                return (
                                    <Draggable key={section.sectionId} draggableId={section.sectionId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >

                                                <div key={section.sectionId} style={{ display: "flex", justifyContent: "center", padding: "1rem 0 0 0" }}>
                                                    <SectionsTableEditLine section={section} originalSection={section} rowNumber={index + 1} refreshSectionsTable={refreshSectionsTable} refreshManager={props.refreshManager} />
                                                </div>
                                            </div>)}
                                    </Draggable>
                                )
                            })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

function SectionsTableInsertLine(props) {

    const [sectionsTableInsertLineState, setSectionsTableInsertLineState] = useState({
        ...props.section,
        errorObject: { ...emptyErrorObject }
    });

    function handleChange({ target }) {

        // if [target.name] starts with "sectionType", strip off the "-n" trailer used to create unique names
        if ([target.name][0].slice(0, 11) === "sectionType") {
            setSectionsTableInsertLineState({ ...sectionsTableInsertLineState, sectionType: target.value })
        } else {
            setSectionsTableInsertLineState({ ...sectionsTableInsertLineState, [target.name]: target.value });
        }
    }

    async function handleInsert(candidateSection) {

        // validate the candidateSection

        const validationResult = await validateSection("insert", candidateSection, props.originalSection)

        if (validationResult.inputOK) {

            // insert the new section into  the sections collection : but it's not straightforward because of
            // the need to take account of its sectionSequenceNumber. The aim is to get the new section
            // positioned at the top of the display and all existing sections arrayed below it in their
            // current order

            // Start by  increment sectionSequenceNumber for all the existing section docs

            const sectionsCollRef = collection(db, runMode + "sections");
            const sectionsQuery = query(sectionsCollRef);
            const sectionsSnapshot = await getDocs(sectionsQuery);
            for (let i = 0; i < sectionsSnapshot.size; i++) {
                const sectionsDocRef = doc(db, runMode + 'sections', sectionsSnapshot.docs[i].id);
                await setDoc(sectionsDocRef, { sectionSequenceNumber: i + 1 }, { merge: true });
            };

            // and now insert the new record with sectionSequenceNumber set to 0

            const sectionsDocRef = doc(sectionsCollRef);
            await setDoc(sectionsDocRef, candidateSection);

            toast.success("Yay - new section created");
            setSectionsTableInsertLineState({ ...emptySection }); // clear the data and error indicators
            props.refreshManager(); // refresh the manager table with the new section
            props.refreshSectionsTable();

        } else {
            toast.error(validationResult.errorDetail);
            setSectionsTableInsertLineState({ ...sectionsTableInsertLineState, errorObject: validationResult.errorObject })
        }

    }

    return (

        // The radio buttons for sectionType need to be given unique names - adding a "rowNumber" suffix 
        // seemed to be the best way forward. The "insert" row is allocated row 0, "edit" rows start at 1

        <div>
            <SectionElement section={sectionsTableInsertLineState} handleChange={handleChange} rowNumber={0} />
            <button type='button'
                title='Insert this section'
                onClick={() => {
                    const candidateSection = {...sectionsTableInsertLineState };
                    delete candidateSection.errorObject;  //strip out the errorObject property
                    handleInsert(candidateSection)
                }}
            >Insert</button>

        </div>
    );
}

function SectionsTableEditLine(props) {

    const [sectionsTableEditLineState, setSectionsTableEditLineState] = useState({
        ...props.section,
        errorObject: { ...emptyErrorObject }
    });

    function handleChange({ target }) {

        // if [target.name] starts with "sectionType", strip off the "-n" trailer used to create unique names
        if ([target.name][0].slice(0, 11) === "sectionType") {
            setSectionsTableEditLineState({ ...sectionsTableEditLineState, sectionType: target.value })
        } else {
            setSectionsTableEditLineState({ ...sectionsTableEditLineState, [target.name]: target.value });
        }
    }

    async function handleUpdate() {

        // validate the candidateSection and sourceFilename

        const validationResult = await validateSection("update", sectionsTableEditLineState, props.originalSection)

        if (validationResult.inputOK) {

            // update the sections collection 

            const sectionsDocRef = doc(db, runMode + "sections", sectionsTableEditLineState.docId);
            await setDoc(sectionsDocRef,
                {
                    "sectionHeader": sectionsTableEditLineState.sectionHeader,
                    "sectionId": sectionsTableEditLineState.sectionId,
                    "sectionPrefix": sectionsTableEditLineState.sectionPrefix,
                    "sectionSequenceNumber": sectionsTableEditLineState.sectionSequenceNumber,
                }, { merge: true });

            toast.success("Yay - section successfully updated");
            setSectionsTableEditLineState({ ...sectionsTableEditLineState, errorObject: validationResult.errorObject })
            props.refreshManager(); // refresh the manager table with the new section
        } else {
            toast.error("Oops - " + validationResult.errorDetail);
            setSectionsTableEditLineState({ ...sectionsTableEditLineState, errorObject: validationResult.errorObject })
        }

    }

    async function handleDelete() {

        if (window.confirm("Did you really mean to delete this section?")) {

            const validationResult = await validateSection("delete", sectionsTableEditLineState, props.originalSection)

            if (validationResult.inputOK) {

                try {
                    const sectionsDocRef = doc(db, runMode + "sections", sectionsTableEditLineState.docId);
                    deleteDoc(sectionsDocRef).then(() => {

                        props.refreshSectionsTable();
                        props.refreshManager(); // refresh the manager table as well so the dropdown gets updatd
                        toast.success("Bye bye section!");
                    })
                } catch (error) {
                    window.alert("Oops - System error. Contact Martin and quote error code " + error);
                    return
                };
            } else {
                toast.error("Oops - " + validationResult.errorDetail)
            }

        }
    }

    return (

        // The radio buttons for sectionType need to be given unique names - adding a "rowNumber" suffix
        // seemed to be the best way forward. The "insert" row is allocated row 0, "edit" rows start at 1

        <div>
            <SectionElement section={sectionsTableEditLineState} handleChange={handleChange} rowNumber={props.rowNumber} />
            <button type='button'
                title='Update this section'
                onClick={handleUpdate}>Update
            </button>&nbsp;&nbsp;
            <button type='button'
                title='Delete this section'
                onClick={handleDelete}>Delete
            </button>
        </div>
    );
}

function SectionElement(props) {

    return (
        <>

            <label>&nbsp;&nbsp;Section id :&nbsp;</label>
            <input type='text' maxLength='10' size='8'
                style={props.section.errorObject.sectionIdStyle}
                name='sectionId'
                value={props.section.sectionId}
                autoComplete='off'
                title='Enter a short tag for the section - eg finstats'
                onChange={props.handleChange} />&nbsp;&nbsp;&nbsp;

            <label>&nbsp;&nbsp;Section header :&nbsp;</label>
            <input type='text' maxLength='40' size='15'
                style={props.section.errorObject.sectionHeaderStyle}
                name='sectionHeader'
                value={props.section.sectionHeader}
                autoComplete='off'
                title='Enter a heading for this section - eg Financial Statements and Accounts'
                onChange={props.handleChange} />&nbsp;&nbsp;&nbsp;

            <label>&nbsp;&nbsp;Section prefix :&nbsp;</label>
            <input type='text' maxLength='20' size='10'
                style={props.section.sectionPrefixStyle}
                name='sectionPrefix'
                value={props.section.sectionPrefix}
                autoComplete='off'
                title='For "date-type" sections enter the prefix for this section (if any) - eg "Minutes for : "'
                onChange={props.handleChange} />&nbsp;&nbsp;&nbsp;

            <label>&nbsp;&nbsp;Date type&nbsp;</label>
            <input type='radio'
                name={'sectionType' + props.rowNumber}
                value='date_title'
                checked={props.section.sectionType === "date_title"}
                title='Check this button to include a date in section entry titles'
                onChange={props.handleChange} />&nbsp;&nbsp;&nbsp;

            <label>&nbsp;&nbsp;Standard type&nbsp;</label>
            <input type='radio'
                name={'sectionType' + props.rowNumber}
                value='standard_title'
                checked={props.section.sectionType === "standard_title"}
                title='Check this button if you do not need dates in section entry titles'
                onChange={props.handleChange} />&nbsp;&nbsp;&nbsp;
        </>
    );
}


export { SectionsTable };