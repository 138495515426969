import React from 'react';
import { createRoutesFromElements, createBrowserRouter, RouterProvider, Route } from "react-router-dom"; 
import ReactDOM from "react-dom/client"; 

import { Home } from './routes/Home';
import { Manager } from './routes/Manager';

import { runMode } from './lib/getRunMode';

// we import globalstyles here, but basically it's unnecessary as it's also imported in every component
// as a reminder. A stylesheet imported anywhere becomes available to all other components - they're not
// scoped unless you use some of the horrible technologies described here:
// https://www.smashingmagazine.com/2020/05/styling-components-react/

import './styles/globalstyles.css';

if (runMode === "test_") document.body.style.backgroundColor = 'lavender';
if (runMode === "emul_") document.body.style.backgroundColor = 'thistle';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

// Desktop AppArch website

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
        <Route path="" element={<Home />} />
        <Route path="/manager" element={<Manager />} />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    < RouterProvider router={router} />
  </React.StrictMode>
);

