import React, { useState } from "react";

import { deviceType } from '../lib/getDeviceType';

import '../styles/globalstyles.css';

const SLIDES = [
    {
        src: "Milburn Village from the air (courtesy visitcumbria.com).jpg",
        alt: "Milburn Village from the air (courtesy visitcumbria.com)",
        title: "Milburn Village from the air (courtesy visitcumbria.com)"
    },
    {
        src: "Milburn Village Church (courtesy visitcumbria.com).jpg",
        alt: "Milburn Village Church (courtesy visitcumbria.com)",
        title: "Milburn Village Church (courtesy visitcumbria.com)"
    },
    {
        src: "Milburn Village Hall.jpg",
        alt: "Milburn Village Hall",
        title: "Milburn Village Hall"
    },
    {
        src: "Milburn Village School (courtesy visitcumbria.com).jpg",
        alt: "Milburn Village School (courtesy visitcumbria.com)",
        title: "Milburn Village School (courtesy visitcumbria.com)"
    },
]

function Carousel() {

    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            newIndex = SLIDES.length - 1;
        } else if (newIndex >= SLIDES.length) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    return (
        <div>
            <div style={deviceType === "mobile" ? { width: '90%', margin: 'auto' } : { width: '60%', margin: 'auto' }}>
                <img
                    style={{ display: "inline-block", height: "auto", width: "100%", border: "1.5px solid black" }}
                    src={"/assets/mobile_assets/" + SLIDES[activeIndex].src}
                    aria-label={SLIDES[activeIndex].alt}
                    alt="carousel slide"
                />
            </div>
            <div style={deviceType === "mobile" ? { width: '90%', margin: 'auto', height: 0 } : { width: '60%', margin: 'auto', height: 0 }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "relative",
                    top: "-3rem",
                    width: "95%",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    height: '3rem'
                }}>

                    <img
                        src="/assets/common_assets/caret_left_special.png"
                        alt="carousel retreat"
                        aria-label="carousel retreat"
                        title="carousel retreat"
                        style={{ height: "1rem" }}
                        tabIndex='0'
                        onClick={() => (updateIndex(activeIndex - 1))}
                    />
                    <div style={{ width: "85%", textAlign: "center", color: "white" }}>{SLIDES[activeIndex].title}</div>
                    <img
                        src="/assets/common_assets/caret_right_special.png"
                        alt="carousel advance"
                        aria-label="carousel advance"
                        title="carousel advance"
                        style={{ height: "1rem" }}
                        tabIndex='0'
                        onClick={() => (updateIndex(activeIndex + 1))}
                    />

                </div>

            </div>
        </div>

    );

}

export { Carousel };
