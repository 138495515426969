import { collection, query, getDocs, where } from 'firebase/firestore';

import { db } from '../lib/firebase_config.js';
import { runMode } from '../lib/getRunMode';

const emptyErrorObject = {
    entryTitleRootStyle: { color: "black" },
    entryTitleSuffixStyle: { color: "black" },
    sourceFilenameStyle: { color: "black" },
}

async function validateEntry(action, currentSection, candidateEntry, sourceFilename) {

    // action - "insert", "update"
    //
    // return an object with the following properties:
    //      inputOK: boolean, 
    //      errorObject: formatting object - see below 
    //      errorDetail: string

    const errorObject = { ...emptyErrorObject }

    // get the existing entries for the candidateEntry's section

    const existingEntries = [];
    const entriesCollRef = collection(db, runMode + "entries");
    const entriesQuery = query(entriesCollRef, where("entrySectionId", "==", currentSection.sectionId));
    const entriesSnapshot = await getDocs(entriesQuery);
    for (let i = 0; i < entriesSnapshot.size; i++) {
        existingEntries.push(entriesSnapshot.docs[i].data());
    };

    if (currentSection.sectionType === "standard_title") {
        if (candidateEntry.entryTitleRoot === '') {
            errorObject.entryTitleRootStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - you need to specify an entry title" };
        }

        // see if there's a match for the candidate fields in existing entries (and, for "update" action
        // excluding itself)

        let matched = false;
        if (action === "insert") {
            for (let i = 0; i < existingEntries.length; i++) {
                if (existingEntries[i].entryTitleRoot === candidateEntry.entryTitleRoot) {
                    matched = true;
                }
            };
        } else {
            let existenceCount = 0;
            for (let i = 0; i < existingEntries.length; i++) {
                if (existingEntries[i].entryTitleRoot === candidateEntry.entryTitleRoot) {
                    existenceCount++;
                }
            };
            if (existenceCount > 1) matched = true
        }
        if (matched) {
            errorObject.entryTitleRootStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - title needs to be unique within this section" };
        }
    }

    if (currentSection.sectionType === "date_title") {
        if (candidateEntry.entryTitleRoot === '') {
            errorObject.entryTitleRootStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - you need to specify an entry date" };
        }

        // see if there's a match for the candidate fields in other entries 
        let matched = false;
        if (action === "insert") {
            for (let i = 0; i < existingEntries.length; i++) {
                if (existingEntries[i].entryTitleRoot === candidateEntry.entryTitleRoot && existingEntries[i].entryTitleSuffix === candidateEntry.entryTitleSuffix) {
                    matched = true;
                }
            };
        } else {
            let existenceCount = 0;
            for (let i = 0; i < existingEntries.length; i++) {
                if (existingEntries[i].entryTitleRoot === candidateEntry.entryTitleRoot && existingEntries[i].entryTitleSuffix === candidateEntry.entryTitleSuffix) {
                    existenceCount++;
                }
            };
            if (existenceCount > 1) matched = true
        }
        if (matched) {
            errorObject.entryTitleRootStyle = { color: 'red' };
            errorObject.entryTitleSuffixStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - the combination of date and suffix needs to be unique within this section" };
        }
    }

    if (action === "insert" && sourceFilename === '') {
        errorObject.sourceFilenameStyle = { color: 'red' };
        return { inputOK: false, errorObject, errorDetail: "Oops - you need to specify a filename" };
    }

    return { inputOK: true, errorObject, errorDetail: "" };
}

export { validateEntry, emptyErrorObject }