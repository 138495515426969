import React, { useState, useEffect } from "react";
import { collection, query, getDocs, orderBy, where, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, deleteObject, updateMetadata } from 'firebase/storage';

import { toast } from 'react-toastify';
import { StandardToastContainer } from '../components/StandardToastContainer';

import { db, storage } from '../lib/firebase_config.js';
import { runMode } from '../lib/getRunMode';
import { validateEntry, emptyErrorObject } from '../lib/validateEntry';

import '../styles/globalstyles.css';

function EntriesTable(props) {

    const [entriesTableState, setEntriesTableState] = useState({
        entries: [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        // Get entries for the currently-selected section
        async function getEntriesWrapper() {
            const entries = await getEntries();
            setEntriesTableState({ ...entriesTableState, entries: entries })
        }

        // don't bother to await this - nothing depends on it
        getEntriesWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentSection.sectionId]); // triggers a table refresh when the section changes in EntriesTable's Manager "owner"

    async function getEntries() {

        const entries = [];

        const entriesCollRef = collection(db, runMode + "entries");
        const sortOrder = (props.currentSection.sectionType === "standard_title") ? "asc" : "desc"
        const entriesQuery = query(entriesCollRef, orderBy("entryTitleRoot", sortOrder), where("entrySectionId", "==", props.currentSection.sectionId));
        const entriesSnapshot = await getDocs(entriesQuery);
        for (let i = 0; i < entriesSnapshot.size; i++) {
            entries.push({ ...entriesSnapshot.docs[i].data(), docId: entriesSnapshot.docs[i].id });
        }

        return entries;
    }

    async function refreshEntriesTable() { // triggers a table refresh following inserts and deletes initiated by EntriesTable's subordinate components
        const entries = await getEntries();
        setEntriesTableState({ ...entriesTableState, entries: entries })
    }

    return (
        <div>
            <StandardToastContainer />
            <h2 style={{
                textAlign: "center",
                marginTop: "3vh"
            }}>Manage Entries for the "{props.currentSection.sectionId}" Section</h2><br />

            <div style={{ display: "flex", justifyContent: "center", padding: "1rem 0 1rem 0" }}>
                <EntriesTableInsertLine currentSection={props.currentSection} refreshEntriesTable={refreshEntriesTable} />
            </div>

            <hr style={{ width: '25%', height: '2px', background: 'black' }}></hr>

            {entriesTableState.entries.map((entry) => {
                const entryKey = entry.sectionId + "/" + entry.entryTitleRoot + "/" + entry.entryTitleSuffix
                return (
                    <div key={entryKey} style={{ display: "flex", justifyContent: "center", padding: "1rem 0 0 0" }}>
                        <button className="buttonstyle" type='button'
                            title='Preview the file currently linked to this entry'
                            onClick={(e) => {
                                const dateObject = new Date();
                                const versionNumber = dateObject.getTime(); // milliseconds since epoch
                                window.open("https://storage.googleapis.com/mpclive-f1256.appspot.com/" + runMode + "entry_files/" + entry.associatedFilename + "?ver = " + versionNumber)
                            }}>Preview</button>

                        <EntriesTableEditLine entry={entry} currentSection={props.currentSection} refreshEntriesTable={refreshEntriesTable} />

                    </div>)
            }
            )}
        </div>
    )
}

function EntriesTableInsertLine(props) {

    const emptyEntry = {
        entryTitleRoot: "",
        entryTitleSuffix: "",
        sourceFilename: "",
        errorObject: { ...emptyErrorObject }
    }

    const [entriesTableInsertLineState, setEntriesTableInsertLineState] = useState({
        ...emptyEntry
    });

    function handleChange({ target }) {
        setEntriesTableInsertLineState({ ...entriesTableInsertLineState, [target.name]: target.value });
    }

    let fileInput = React.useRef(); // important - don't use createRef (this is a function rather than a hook and is upset by asynch activity and multiple renders)

    async function handleInsert(currentSection, candidateEntry, sourceFilename) {

        // validate the candidateEntry and sourceFilename

        const validationResult = await validateEntry("insert", currentSection, candidateEntry, sourceFilename)

        if (validationResult.inputOK) {

            // first upload the file and then, if this is OK, add the entry to the entries collection              

            const storageRef = ref(storage, runMode + "entry_files/" + candidateEntry.associatedFilename); //  the storage object has been set by firebase.js and is pointing at the default bucket

            // get the source file

            try {
                let sourceFileObject = fileInput.current.files[0];

                // upload the file content
                await uploadBytes(storageRef, sourceFileObject)

                // add metadata to prevent google caching

                let newMetadata = { cacheControl: 'private,max-age=0,no-store' };
                await updateMetadata(storageRef, newMetadata)

                // now add the entry to the entries collection 

                const entriesCollRef = collection(db, runMode + "entries");
                const entriesDocRef = doc(entriesCollRef);
                await setDoc(entriesDocRef, candidateEntry);

                toast.success("Yay - new entry created");
                setEntriesTableInsertLineState({ ...emptyEntry }) // clear the data and error indicators
                props.refreshEntriesTable() // refresh the entries table with its new entry
                fileInput.current.value = "" // clear the file input field

            } catch (error) {
                window.alert("Oops - System error. Contact Martin and quote error code " + error);
                return;
            };

        } else {
            toast.error(validationResult.errorDetail);
            setEntriesTableInsertLineState({ ...entriesTableInsertLineState, errorObject: validationResult.errorObject })
        }

    }

    return (

        <div>
            {props.currentSection.sectionType === "standard_title" &&
                <span>
                    <label>&nbsp;&nbsp;Entry Title :&nbsp;&nbsp;</label>
                    <input type='text' maxLength='40' size='20'
                        style={entriesTableInsertLineState.errorObject.entryTitleRootStyle}
                        name='entryTitleRoot'
                        value={entriesTableInsertLineState.entryTitleRoot}
                        autoComplete='off'
                        title='Enter the title for this entry'
                        onChange={handleChange} />&nbsp;&nbsp;&nbsp;
                </span>
            }

            {props.currentSection.sectionType === "date_title" &&
                <span>
                    <label>&nbsp;&nbsp;{props.currentSection.sectionPrefix} :&nbsp;</label>
                    <input type="date"
                        style={entriesTableInsertLineState.errorObject.entryTitleRootStyle}
                        name='entryTitleRoot'
                        value={entriesTableInsertLineState.entryTitleRoot}
                        autoComplete='off'
                        title='Enter the date for this entry'
                        onChange={handleChange} />
                    <label>&nbsp;&nbsp;Suffix :&nbsp;&nbsp;</label>
                    <input type='text' maxLength='40' size='10'
                        style={entriesTableInsertLineState.errorObject.entryTitleSuffixStyle}
                        name='entryTitleSuffix'
                        value={entriesTableInsertLineState.entryTitleSuffix}
                        autoComplete='off'
                        title='Enter the suffix for this entry (if any)'
                        onChange={handleChange} />&nbsp;&nbsp;
                </span>
            }

            <label>&nbsp;&nbsp;Filename :&nbsp;</label>
            <input type='file' ref={fileInput}
                style={entriesTableInsertLineState.errorObject.sourceFilenameStyle}
                accept='application/pdf'
                title='Select a pdf file for this entry'
            />&nbsp;&nbsp;&nbsp;

            <button className="buttonstyle" type='button'
                title='Insert this entry'
                onClick={() => {

                    // build a candidate entry object

                    const entryTitleSuffix = (props.currentSection.sectionType === "standard_title") ? "" : entriesTableInsertLineState.entryTitleSuffix

                    const candidateEntry = {
                        associatedFilename: crypto.randomUUID() + ".pdf",
                        entrySectionId: props.currentSection.sectionId,
                        entryTitleRoot: entriesTableInsertLineState.entryTitleRoot,
                        entryTitleSuffix: entryTitleSuffix,
                    }

                    // get the source file name (for validation purposes) - make sure we don't pass an "undefined"

                    let sourceFilename = "";
                    let sourceFileObject = {};
                    try {
                        sourceFileObject = fileInput.current.files[0];
                        sourceFilename = sourceFileObject.name;
                    } catch {
                    }

                    handleInsert(props.currentSection, candidateEntry, sourceFilename)
                }}
            >Insert</button>

        </div>
    );
}

function EntriesTableEditLine(props) {

    const [entriesTableEditLineState, setEntriesTableEditLineState] = useState({
        entryTitleRoot: props.entry.entryTitleRoot,
        entryTitleSuffix: props.entry.entryTitleSuffix,
        docId: props.entry.docId,
        associatedFilename: props.entry.associatedFilename,
        errorObject: { ...emptyErrorObject },
    });

    let fileInput = React.useRef(); // important - don't use createRef (this is a function rather than a hook and is upset by asynch activity and multiple renders)

    function handleChange({ target }) {
        setEntriesTableEditLineState({ ...entriesTableEditLineState, [target.name]: target.value });
    }

    async function handleUpdate(currentSection, candidateEntry, candidateEntryId, sourceFilename) {

        // validate the candidateEntry and sourceFilename

        const validationResult = await validateEntry("update", currentSection, candidateEntry, sourceFilename)

        if (validationResult.inputOK) {

            // if a new file has been provided, upload it  

            if (sourceFilename !== "") {

                const storageRef = ref(storage, runMode + "entry_files/" + candidateEntry.associatedFilename); //  the storage object has been set by firebase.js and is pointing at the default bucket

                // get the source file

                try {
                    let sourceFileObject = fileInput.current.files[0];

                    // upload the file content
                    await uploadBytes(storageRef, sourceFileObject)

                    // add metadata to prevent google caching (cutover files had no metadata set)
                    let newMetadata = { cacheControl: 'private,max-age=0,no-store' };
                    await updateMetadata(storageRef, newMetadata)

                    // update the collection with any changes to entryTitleRoot or entryTitleSuffix
                    const entriesDocRef = doc(db, runMode + "entries", candidateEntryId);
                    await setDoc(entriesDocRef, { "entryTitleRoot": candidateEntry.entryTitleRoot, "entryTitleSuffix": candidateEntry.entryTitleSuffix }, { merge: true });

                    toast.success("Yay - entry successfully updated");
                    setEntriesTableEditLineState({ ...entriesTableEditLineState, errorObject: { ...emptyErrorObject } }) // clear the data and error indicators
                    fileInput.current.value = "" // clear the file input field

                } catch (error) {
                    window.alert("Oops - System error. Contact Martin and quote error code " + error);
                    return;
                };

            } else {
                toast.error(validationResult.errorDetail);
                setEntriesTableEditLineState({ ...entriesTableEditLineState, errorObject: validationResult.errorObject })
            }
        }
    }

    async function handleDelete() {

        if (window.confirm("Did you really mean to delete this entry?")) {

            const storageRef = ref(storage, runMode + "entry_files/" + entriesTableEditLineState.associatedFilename);

            deleteObject(storageRef).then(() => {
                // File deleted successfully, now delete the entry collections entry
                const entriesDocRef = doc(db, runMode + "entries", entriesTableEditLineState.docId);
                deleteDoc(entriesDocRef).then(() => {

                    props.refreshEntriesTable();
                    toast.success("Bye bye entry!");
                })
            }).catch((error) => {
                window.alert("Oops - System error. Contact Martin and quote error code " + error);
                return
            });


        }
    }

    return (

        <div>
            {props.currentSection.sectionType === "standard_title" &&
                <span>
                    <label>&nbsp;&nbsp;Entry Title :&nbsp;&nbsp;</label>
                    <input className="inputstyle" type='text' maxLength='40' size='20'
                        style={entriesTableEditLineState.errorObject.entryTitleRootStyle}
                        name='entryTitleRoot'
                        value={entriesTableEditLineState.entryTitleRoot}
                        autoComplete='off'
                        title='Enter the title for this entry'
                        onChange={handleChange} />&nbsp;&nbsp;&nbsp;
                </span>
            }

            {props.currentSection.sectionType === "date_title" &&
                <span>
                    <label>&nbsp;&nbsp;{props.currentSection.sectionPrefix} :&nbsp;</label>
                    <input type="date"
                        style={entriesTableEditLineState.errorObject.entryTitleRootStyle}
                        name='entryTitleRoot'
                        value={entriesTableEditLineState.entryTitleRoot}
                        autoComplete='off'
                        title='Enter the date for this entry'
                        onChange={handleChange} />
                    <label>&nbsp;&nbsp;Suffix :&nbsp;&nbsp;</label>
                    <input type='text' maxLength='40' size='10'
                        style={entriesTableEditLineState.errorObject.entryTitleSuffixStyle}
                        name='entryTitleSuffix'
                        value={entriesTableEditLineState.entryTitleSuffix}
                        autoComplete='off'
                        title='Enter the suffix for this entry (if any)'
                        onChange={handleChange} />&nbsp;&nbsp;
                </span>
            }

            <label>&nbsp;&nbsp;Filename :&nbsp;&nbsp;</label>
            <input type='file' ref={fileInput}
                style={entriesTableEditLineState.errorObject.sourceFilenameStyle}
                accept='application/pdf'
                title='Select a pdf file for this entry' />&nbsp;&nbsp;&nbsp;

            <button className="buttonstyle" type='button'
                title='Update this entry'
                onClick={() => {

                    // build a candidate entry object

                    const candidateEntry = {
                        associatedFilename: entriesTableEditLineState.associatedFilename,
                        entryTitleRoot: entriesTableEditLineState.entryTitleRoot,
                        entryTitleSuffix: entriesTableEditLineState.entryTitleSuffix,
                    }

                    // get the source file name (if any) - make sure we don't pass an "undefined"

                    let sourceFilename = "";
                    let sourceFileObject = {};
                    try {
                        sourceFileObject = fileInput.current.files[0];
                        sourceFilename = sourceFileObject.name;
                    } catch {
                    }

                    handleUpdate(props.currentSection, candidateEntry, entriesTableEditLineState.docId, sourceFilename)

                }}>Update
            </button>&nbsp;&nbsp;&nbsp;

            <button className="buttonstyle" type='button'
                title='Delete this entry'
                onClick={handleDelete}>Delete
            </button>

        </div>
    );
}


export { EntriesTable };