import { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy, } from 'firebase/firestore';

import { Login } from '../components/Login';
import { SectionsTable } from '../components/SectionsTable';
import { EntriesTable } from '../components/EntriesTable';

import { db } from '../lib/firebase_config.js';
import { runMode } from '../lib/getRunMode';

import '../styles/globalstyles.css';

function Manager() {

    const [managerScreenState, setManagerScreenState] = useState({
        userIsLoggedIn: false,
        sections: [],
        activeManagementTable: "entries",
        currentSection: {},
    });

    useEffect(() => {
        // define an async function to get a copy of the sections docs and put this 
        // into state with the first of these as the selected item
        async function getSectionsWrapper() {
            const sections = await getSections();
            setManagerScreenState({ ...managerScreenState, sections: sections, currentSection: sections[0] })
        }

        // don't bother to await this - nothing depends on it
        getSectionsWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSections() {

        const sections = [];

        const sectionsCollRef = collection(db, runMode + "sections");
        const sectionsQuery = query(sectionsCollRef, orderBy("sectionSequenceNumber", "asc"));
        const sectionsSnapshot = await getDocs(sectionsQuery);
        for (let i = 0; i < sectionsSnapshot.size; i++) {
            sections.push({ ...sectionsSnapshot.docs[i].data(), docId: sectionsSnapshot.docs[i].id });
        }

        return sections;
    }

    async function handleChange({ target }) {

        // find the selected section in managerScreenState.sections and put it into
        // managerScreenState.currentSection

        for (let i = 0; i < managerScreenState.sections.length; i++) {
            if (managerScreenState.sections[i].sectionId === target.value) {
                setManagerScreenState({
                    ...managerScreenState,
                    [target.name]: target.value,
                    activeManagementTable: "entries",
                    currentSection: managerScreenState.sections[i],
                });

            }
        }
    }

    function setUserLoginState() {
        setManagerScreenState({ ...managerScreenState, userIsLoggedIn: true })
    }

    function toggleActiveManagementTable() {
        if (managerScreenState.activeManagementTable === "entries") {
            setManagerScreenState({ ...managerScreenState, activeManagementTable: "sections" })
        } else
            setManagerScreenState({ ...managerScreenState, activeManagementTable: "entries" })
    }

    async function refreshManager() {
        // a function that enables sectionsTable to tell the Manager screen that a new section has
        // been created or an existing one either deleted or given a new name
        const sections = await getSections();
        setManagerScreenState({ ...managerScreenState, sections: sections })
    }

    return (
        <div>
            {managerScreenState.userIsLoggedIn ?
                <div>
                    <h2 style={{ width: "80%", margin: "3vh auto 0 auto", textAlign: "center", padding: "1vh 0 1vh 0", background: "paleturquoise", border: "1px solid black" }}>
                        Management Screen for the Milburn Parish Council Website
                    </h2>

                    <div style={{ display: "flex", width: "50%", justifyContent: "space-around", border: "1px solid black", margin: "3vh auto 0 auto" }}>
                        <div className="linkstyle"
                            title="Add/remove/edit Entries for the selected section"
                        >Manage Entries for the&nbsp;
                            &nbsp;&nbsp;<select
                                value={managerScreenState.selectedSection}
                                name="selectedSection"
                                title="Select a section for management"
                                style={{ maxWidth: "12rem" }}
                                onClick={handleChange}   // a bit of overkill here, on the face of it but we need to be able to select
                                onChange={handleChange}  // the section both when the current table is "entries" and when it's "sections"
                            >
                                {managerScreenState.sections.map((section) => {
                                    return <option value={section.sectionId} key={section.sectionId}>{section.sectionId}</option>;
                                })}
                            </select>
                            <span> Section</span>
                        </div>
                        <p className="linkstyle"
                            title="Add/remove/reorder/edit Sections"
                            onClick={toggleActiveManagementTable}>Configure Sections
                        </p>
                    </div >

                    <div>
                        {managerScreenState.activeManagementTable === "entries" ?

                            <EntriesTable currentSection={managerScreenState.currentSection} /> :

                            <SectionsTable sections={managerScreenState.sections} refreshManager={refreshManager} />}
                    </div>
                </div> :
                <Login setUserLoginState={setUserLoginState} />}

        </div>
    );
}

export { Manager };
