import { collection, query, getDocs } from 'firebase/firestore';

import { db } from './firebase_config.js';
import { runMode } from './getRunMode';

const emptyErrorObject = {
    sectionHeaderStyle: { color: "black" },
    sectionIdStyle: { color: "black" },
    sectionPrefixStyle: { color: "black" },
    sectionTypeStyle: { color: "black" },
    sectionSequenceNumberStyle: { color: "black" },
}

async function validateSection(action, candidateSection, originalSection) {

    // action - "insert", "update"
    //
    // return an object with the following properties:
    //      inputOK: boolean, 
    //      errorObject: formatting object - see below 
    //      errorDetail: string

    const errorObject = { ...emptyErrorObject }

    // get the existing sections

    const existingSections = [];
    const sectionsCollRef = collection(db, runMode + "sections");
    const sectionsQuery = query(sectionsCollRef);
    const sectionsSnapshot = await getDocs(sectionsQuery);
    for (let i = 0; i < sectionsSnapshot.size; i++) {
        existingSections.push(sectionsSnapshot.docs[i].data());
    };

    // and the existing entries - could do the validation against the d/b but given that entries collection
    // will always be manageably small,it seems easier to do validation checks against a local array

    const existingEntries = [];
    const entriesCollRef = collection(db, runMode + "entries");
    const entriesQuery = query(entriesCollRef);
    const entriesSnapshot = await getDocs(entriesQuery);
    for (let i = 0; i < entriesSnapshot.size; i++) {
        existingEntries.push(entriesSnapshot.docs[i].data());
    };

    let matched = '';

    if (action === "insert" || action === "update") {

        if (candidateSection.sectionId === '') {
            errorObject.sectionIdStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - you need to supply a Section id for the section" };
        }

        if (candidateSection.sectionHeader === '') {
            errorObject.sectionHeaderStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - you need to supply a header for the section" };
        }

        if (candidateSection.sectionType === "date_title" && candidateSection.sectionPrefix === '') {
            errorObject.sectionPrefixStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - sectionPrefix should be specified for a Date Type section" };
        }

        if (candidateSection.sectionType === "standard_title" && candidateSection.sectionPrefix !== '') {
            errorObject.sectionPrefixStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - sectionPrefix should not be specified for a Standard Type section" };
        }

        // see if there's already a sectionId for candidateSection.sectionId

        let count = 0;
        for (let i = 0; i < existingSections.length; i++) {
            if (existingSections[i].sectionId === candidateSection.sectionId) {
                count++
            }
        };

        if (action === "insert" && count > 0) {
            errorObject.sectionIdStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - there's already a section with this sectionId" };
        }
        if (action === "update" && count > 0 && candidateSection.sectionId !== originalSection.sectionId) {
            errorObject.sectionIdStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - there's already a section with this sectionId" };
        }
    }

    if (action === "update") {
        // see if the existing section already has entries - in which case we need to prevent sectionType changes

        matched = false;
        for (let i = 0; i < existingEntries.length; i++) {
            if (existingEntries[i].entrySectionId === candidateSection.sectionId) {
                i = existingEntries.length;
                matched = true;
            }
        };

        if (matched && candidateSection.sectionType !== originalSection.sectionType) {
            errorObject.sectionTypeStyle = { color: 'red' };
            return { inputOK: false, errorObject, errorDetail: "Oops - this section contains entries - please delete these first" };
        }
    }

    // It seems like a good idea to prevent users from deleting sections with associated entries

    if (action === "delete") {

        for (let i = 0; i < existingEntries.length; i++) {
            if (existingEntries[i].entrySectionId === candidateSection.sectionId) {
                i = existingEntries.length;
                errorObject.sectionIdStyle = { color: 'red' };
                return { inputOK: false, errorObject, errorDetail: "Oops - this section contains entries - please delete these first" };
            }
        };

    }

    return { inputOK: true, errorObject, errorDetail: "" };
}

export { validateSection, emptyErrorObject }