import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

import { runMode } from './getRunMode';

const firebaseConfig = {
    apiKey: "AIzaSyD2S1O6gOJhsAXZPQ8eHSp3aM1a8ZEUzk8",
    authDomain: "mpclive-f1256.firebaseapp.com",
    projectId: "mpclive-f1256",
    storageBucket: "mpclive-f1256.appspot.com",
    messagingSenderId: "125124481834",
    appId: "1:125124481834:web:411b15747eb1b93e3dc453",
    measurementId: "G-GQ80T2J9TC"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
if (runMode === "emul_") connectFirestoreEmulator(db, 'localhost', 8080);

const functions = getFunctions(app, 'europe-west2');
if (runMode === "emul_") connectFunctionsEmulator(functions, "localhost", 5001);

const storage = getStorage();
if (runMode === "emul_") connectStorageEmulator(storage, "localhost", 9199);

//const provider = new GoogleAuthProvider();
const auth = getAuth();

export { firebaseConfig, db, storage, auth, app, functions }
