import React, { useState } from 'react';

import { StandardToastContainer } from '../components/StandardToastContainer';
import { toast } from 'react-toastify';

import { auth } from '../lib/firebase_config';
import { loginapilink } from '../lib/loginapilink';

import '../styles/globalstyles.css';

function Login(props) {

    const [stateObject, setStateObject] = useState(
        {
            email: "",
            password: "",
            userIsLoggedIn: false,
        });

    function handleChange({ target }) {
        // We need to keep state in line with the DOM at all time, so setState when anything changes. Set input field
        // styles to black so that an error input will change its style back to black as soon as you start typing

        setStateObject({ ...stateObject, [target.name]: target.value });
    };

    return (
        <div>
            <StandardToastContainer />
            <div style={{ width: '100%', textAlign: 'center' }}>
                <img alt='Website logo' style={{
                    maxWidth: '4rem', marginTop: '1rem', border: '1px solid black', padding: '1rem', background: 'lightskyblue'
                }}
                    src="/assets/login_assets/mpc_logo.png" />
                <p style={{ fontSize: '2rem', marginTop: '1rem' }}>
                    MPC Login Panel
                </p>

                <div style={{ fontSize: "1.1rem", border: '1px solid black', padding: '1rem', width: '30%', margin: '1rem auto 0rem auto' }}>

                    <form autoComplete="on">

                        <div>
                            <label>Email:&nbsp;&nbsp;</label>

                            {/*see https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#the_autocomplete_attribute_and_login_fields 
                            for info on the auto-completion of email and password fields - look for the section headed 'the autocompete attribute and login fields' 
                            
                            The curious reduction of font-size seems to be a design feature of autofill - see stackoverflow at
                            https://stackoverflow.com/questions/56026043/how-to-prevent-chrome-from-changing-font-when-autofilling-username-password
                            This seems to extend to the "Login" content of the login <button element - bizarre! */}

                            <input
                                style={{ marginTop: "1rem" }}
                                type="email"
                                name="email"
                                size="20"
                                title="Enter your email Address"
                                onChange={handleChange} />
                        </div>

                        <div>
                            <label>Password:&nbsp;&nbsp;</label>
                            <input
                                style={{ marginTop: "1rem", backgroundColor: 'rgb(232, 240, 254)' }}
                                type="password"
                                name="password"
                                size="20"
                                title="Enter your Password for this system"
                                onChange={handleChange}
                                tabIndex="-1" />
                        </div>

                        <p style={{ marginTop: "1.5rem" }}>
                            <button type='button'
                                className={(stateObject.email === "" || stateObject.password !== "") ? "apparchbutton" : "selectedapparchbutton"}
                                title='Login to the MPC maintenance system'
                                onClick={async (event) => {
                                    let email = stateObject.email;
                                    let password = stateObject.password;
                                    event.stopPropagation(); // stops other buttons firing inadvertently

                                    // you need to return a promise from the login function in order to get the
                                    // return values
                                    loginapilink(auth, email, password)
                                        .then(async returnValue => {
                                            if (returnValue === 1) {
                                                props.setUserLoginState()
                                                return;
                                            }
                                            alert("Blank User Credentials: " + returnValue.code + " " + returnValue.message);
                                            setStateObject({ ...stateObject, userIsLoggedIn: false })
                                        })
                                        .catch(error => {
                                            if (error === 3) {
                                                toast.error("Oops - invalid email address");
                                                setStateObject({ ...stateObject, userIsLoggedIn: false })
                                                return;
                                            }
                                            if (error === 4) {
                                                toast.error("Oops - invalid password");
                                                setStateObject({ ...stateObject, userIsLoggedIn: false })
                                                return;
                                            }
                                            alert("Firebase error: " + error.code + " " + error.message);
                                            setStateObject({ ...stateObject, userIsLoggedIn: false })
                                        });
                                }}

                            >Login</button>&nbsp;&nbsp;&nbsp;
                        </p>
                    </form>


                </div>

                <p>&nbsp;</p>
            </div>
        </div>
    );
}

export { Login };