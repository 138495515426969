// Returns "test_" if running in localhost, "emul_" if running in local host on the emulator port and "live" otherwise

let modeCalculator = (x) => {
    let result = "live_";
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") result = "test_";
    if (result === "test_" && x.port === '5000') result = "emul_";
    return result;

};

const runMode = modeCalculator(window.location)


export { runMode }