import { useState, useEffect } from 'react';
import { collection, query, getDocs, where, orderBy, } from 'firebase/firestore';

import { Carousel } from '../components/Carousel';

import { db } from '../lib/firebase_config';
import { runMode } from '../lib/getRunMode';
import { deviceType } from '../lib/getDeviceType';

import '../styles/globalstyles.css';

function Home() {

    const [screenState, setScreenState] = useState({ sections: [] });

    useEffect(() => {
        buildScreenState();
        //Runs on the first render and triggers a re-render for each sections document
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function buildScreenState() {

        const screenStateSections = [];

        const sectionsCollRef = collection(db, runMode + "sections");
        const sectionsQuery = query(sectionsCollRef, orderBy("sectionSequenceNumber", "asc"));
        const sectionsSnapshot = await getDocs(sectionsQuery);

        for (let i = 0; i < sectionsSnapshot.size; i++) {
            const newSection = {
                sectionId: sectionsSnapshot.docs[i].data().sectionId,
                sectionType: sectionsSnapshot.docs[i].data().sectionType,
                sectionHeader: sectionsSnapshot.docs[i].data().sectionHeader,
                sectionPrefix: sectionsSnapshot.docs[i].data().sectionPrefix,
                entries: []
            };

            const entriesCollRef = collection(db, runMode + "entries");
            let sortOrder = "asc"
            if (sectionsSnapshot.docs[i].data().sectionType === "date_title") sortOrder = "desc";
            const entriesQuery = query(entriesCollRef, where("entrySectionId", "==", newSection.sectionId), orderBy("entryTitleRoot", sortOrder), orderBy("entryTitleSuffix", "asc"),);

            const entriesSnapshot = await getDocs(entriesQuery);

            for (let j = 0; j < entriesSnapshot.size; j++) {
                newSection.entries.push({
                    associatedFilename: entriesSnapshot.docs[j].data().associatedFilename,
                    entryTitleRoot: entriesSnapshot.docs[j].data().entryTitleRoot,
                    entryTitleSuffix: entriesSnapshot.docs[j].data().entryTitleSuffix
                })
            }

            // trigger a render

            screenStateSections.push(newSection)

            setScreenState({ ...screenState, sections: screenStateSections })
        }

    };

    const wideHeaderStyle = {
        width: "80%",
        textAlign: "center",
        margin: "2vh auto 4vh auto",
        padding: "1.5vh",
        border: "1px solid black",
        background: "paleturquoise"
    }

    return (
        <div style={{ background: 'gainsboro' }}>
            <div style={deviceType === "desktop" ? { width: '75%', background: 'white', margin: 'auto', paddingTop: '2vh' } : { background: 'white' }}>

                <p style={{ ...wideHeaderStyle, fontSize: 'larger', fontWeight: 'bold' }}>Milburn Parish Council</p>

                <Carousel />

                {screenState.sections.map((section) => {
                    return (
                        <Section key={section.sectionId} section={section} />
                    )
                })}

            </div>
        </div>
    );
}

function Section(props) {

    const [sectionState, updateSectionState] = useState(
        { moreButtonNotYetClicked: true }
    );

    const entries = [];

    props.section.entries.forEach((entry, index) => {

        var entryDisplaya;
        var entryDisplayb;
        var entryLink;

        if (props.section.sectionType === "standard_title") {
            entryDisplaya = entry.entryTitleRoot;
            entryLink = "https://storage.googleapis.com/mpclive-f1256.appspot.com/" + runMode + "entry_files/" + entry.associatedFilename;
        } else {
            entryDisplaya = props.section.sectionPrefix + " " + entry.entryTitleRoot;
            entryDisplayb = entry.entryTitleSuffix;
            entryLink = "https://storage.googleapis.com/mpclive-f1256.appspot.com/" + runMode + "entry_files/" + entry.associatedFilename;
        }

        entries.push({
            entrySectionType: props.section.sectionType,
            entryDisplaya: entryDisplaya,
            entryDisplayb: entryDisplayb,
            entryLink: entryLink
        }
        );
    });

    let width = '80%';

    if (props.section.sectionType !== "standard_title") {
        width = '60%';
    }

    const sectionStyle = {
        width: width,
        textAlign: "center",
        margin: "auto"
    }

    const headerStyle = {
        marginTop: "3vh",
        fontWeight: "bold",
        fontSize: "larger",
        textAlign: "center"
    };

    const moreButtonStyle = {
        display: "inline-block",
        marginBottom: 0,
        padding: "1vh",
        background: "white",
        cursor: "pointer"
    };

    // display the entry lines and add a "more" button provided that it hasn't been clicked yet and
    // there are at least 5 entries

    return (

        <div style={sectionStyle}>
            {props.section.entries.length > 0 &&
                <div>
                    <p style={headerStyle}>{props.section.sectionHeader}</p>
                    {entries.map((entry, index) => {
                        if ((sectionState.moreButtonNotYetClicked && index < 4) || !sectionState.moreButtonNotYetClicked) {
                            return (
                                <Entry
                                    key={index}
                                    entrySectionType={entry.entrySectionType}
                                    entryDisplaya={entry.entryDisplaya}
                                    entryDisplayb={entry.entryDisplayb}
                                    entryLink={entry.entryLink}
                                    entryBackground={(sectionState.moreButtonNotYetClicked && index === 3) ? "whitesmoke" : "paleturquoise"}
                                />
                            )
                        } else {
                            return null;
                        }
                    })}
                    {/*if the "More" button hasn't been clicked, add a "More" button ag the end of the list
                        otherwise add a "Less" button*/}
                    {(sectionState.moreButtonNotYetClicked && (props.section.entries.length > 4)) &&
                        <p style={moreButtonStyle}
                            role='button'
                            aria-label='Click to display all entries in this section'
                            title='Click to display all entries in this section'
                            tabIndex='0'
                            onClick={() => updateSectionState({ moreButtonNotYetClicked: false })}
                            onKeyDown={() => updateSectionState({ moreButtonNotYetClicked: false })}>More&nbsp;
                            <img style={{ height: '.6rem' }}
                                src="/assets/common_assets/caret_bottom.png"
                                alt='caret-bottom symbol' />
                        </p>}
                    {(!sectionState.moreButtonNotYetClicked && (props.section.entries.length > 4)) &&
                        <p style={moreButtonStyle}
                            role='button'
                            aria-label='Click to display only the most recent entries in this section'
                            title='Click to display only the most recent entries in this section'
                            tabIndex='0'
                            onClick={() => updateSectionState({ moreButtonNotYetClicked: true })}
                            onKeyDown={() => updateSectionState({ moreButtonNotYetClicked: true })}>Less&nbsp;
                            <img style={{ height: '.6rem' }}
                                src="/assets/common_assets/caret_top.png"
                                alt='caret-top symbol' />
                        </p>}
                </div>
            }

        </div>
    );

}

function Entry(props) {

    function handleClick(target, event) {

        // ignore if the (keyboard) event that launched this function call was a tab, tab/shift, ctrl/+, or
        // ctrl/- character

        if (
            event.keyCode === 9 ||
            event.keyCode === 16 ||
            event.keyCode === 17 ||
            event.keyCode === 187 ||
            event.keyCode === 189
        ) {
            return;
        }

        const dateObject = new Date();
        const versionNumber = dateObject.getTime(); // milliseconds since epoch
        window.open(target + "?ver = " + versionNumber, '_blank');
    }

    const entryStyle = {
        display: "inline-block",
        width: "100%",
        padding: "1vh",
        border: "1px solid black",
        background: "paleturquoise",
        cursor: "pointer"
    }

    entryStyle.background = props.entryBackground;

    const entrySpanStyle = {
        textDecoration: "underline",
        textDecorationColor: "blue",
        textUnderlinePosition: "under"
    };

    return (
        <div>
            <p style={entryStyle}
                role='button'
                aria-label='Click to display the pdf file for this entry'
                title='Click to display the pdf file for this entry'
                tabIndex='0'
                // see https://reactjs.org/docs/faq-functions.html
                onClick={(event) => handleClick(props.entryLink, event)}
                onKeyDown={(event) => handleClick(props.entryLink, event)}>

                {/* For "standard-titles" - eg "About" and "Policies" you just get one line, but for "date-titles"
                    like "Minutes", you get a second lines providing a sort of "comment\" - eg "AGM and Parish Meeting"
                */}

                <span style={entrySpanStyle}>
                    {props.entryDisplaya}
                </span>
                {(props.entryDisplaya !== "standard_title") &&
                    <span>
                        <br />
                        {props.entryDisplayb}
                    </span>
                }
            </p>
        </div>
    );
}

export { Home };
